import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { storage } from "./storage";
import { db } from ".";
import { ref, set } from "firebase/database";
import { v4 as uuidv4 } from "uuid";

function App() {
  const [userAnswers, setUserAnswers] = useState<string[]>([]);
  const [step, setStep] = useState(0);
  const [result, setResult] = useState({ d: 0, i: 0, s: 0, c: 0 });
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [form, setForm] = useState(false);
  const writeUserData = (result: object, answers: string[], lastname:string, name:string) => {
    set(ref(db, "disc_test/" + uuidv4()), {
      result: result,
      answers: answers,
      lastname,
      name
    });
  };

  const calcTest = (answers: string[]) => {
    let D = 0;
    let I = 0;
    let S = 0;
    let C = 0;
    answers.map((item) => {
      switch (item) {
        case "D":
          D++;
          break;
        case "I":
          I++;
          break;
        case "S":
          S++;
          break;
        case "C":
          C++;
          break;
        case "DI":
          D++;
          I++;
          break;
        case "SC":
          S++;
          C++;
          break;
        case "DC":
          D++;
          C++;
          break;
        case "IS":
          I++;
          S++;
          break;
      }
    });
    setResult({ d: D, i: I, s: S, c: C });
    writeUserData({ d: D, i: I, s: S, c: C }, answers, lastname, name);
  };

  useEffect(() => {
    step >= storage.questions.length && calcTest(userAnswers);
  }, [step]);

  return !form ? (
    <div className="container mx-auto px-8">
      <div className="text-3xl font-extrabold py-4 text-slate-900">
        Team-тест
      </div>
      <div className="py-4 px-8 border-slate-200 border rounded-lg">
        <label
          htmlFor="input-label"
          className="block text-sm font-medium mb-2 "
        >
          Имя:
        </label>
        <input
          type="email"
          id="input-label"
          className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 "
          placeholder="Ваше имя (обязательно)"
          value={name}
          onChange={(e)=> setName(e.target.value)}
        />
        <label
          htmlFor="input-label"
          className="block text-sm font-medium mb-2 mt-8 "
        >
          Фамилия:
        </label>
        <input
          type="email"
          id="input-label"
          className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 "
          placeholder="Ваша фамилия (обязательно)"
          value={lastname}
          onChange={(e)=> setLastName(e.target.value)}
        />
        <div className="py-4">
          <button
            type="button"
            onClick={() => setForm(true)}
            disabled={name !== '' && lastname !== '' ? false : true}
            className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
          >
            Начать тест
          </button>
        </div>
      </div>
    </div>
  ) : step < storage.questions.length ? (
    <div className="container mx-auto px-8">
      <div className="text-3xl font-extrabold py-4 text-slate-900">
        Вопрос {step + 1} из {storage.questions.length}
      </div>
      {/* <div>{userAnswers.map((item) => item + " ")}</div> */}
      <div className="text-slate-700">
        <div className="py-4 px-8 border-slate-200 border rounded-lg">
          <div className="py-2 font-bold text-slate-800">
            {storage.questions[step].text}
          </div>
          {storage.questions[step].answer.map((item, key) => (
            <div className="flex py-1 " key={`a-${step}-${key}`}>
              <input
                type="radio"
                name={`hs-radio-vertical-group-${step}`}
                id={`hs-radio-vertical-group-${step}-${key}`}
                className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600  focus:ring-blue-500"
                onClick={() =>
                  setUserAnswers(
                    userAnswers[step] === undefined
                      ? [...userAnswers, item.value]
                      : userAnswers.map((itm, indx) =>
                          step === indx ? item.value : itm
                        )
                  )
                }
              />
              <label
                htmlFor={`hs-radio-vertical-group-${step}-${key}`}
                className="text-sm text-gray-500 ml-2 cursor-pointer "
              >
                {item.text}
              </label>
            </div>
          ))}
          <div className="py-4">
            <button
              type="button"
              onClick={() => setStep(step + 1)}
              disabled={userAnswers[step] === undefined && true}
              className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
            >
              Ответить
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="container mx-auto px-8">
      <div className="text-center text-2xl py-8">
        Тест пройден успешно. Спасибо.
      </div>
      {/* <div className="text-center">
        D:{result.d} I:{result.i} S:{result.s} C:{result.c}
      </div> */}
    </div>
  );
}

export default App;
