export const storage = {
  questions: [
    {
      text: "Вы пришли в гости, где собралось уже более 10 человек. Ваша реакция:",
      answer: [
        {
          text: "Здорово! Обожаю шумные компании, можно повеселиться, завести новых знакомых.",
          value: "I",
        },
        {
          text: "Я люблю бывать в компаниях, часто оказываюсь в центре внимания. Или удастся хорошенько зажечь, или уж, на худой конец, с полезными людьми познакомлюсь.",
          value: "D",
        },
        {
          text: "Надеюсь, что встречу здесь каких-нибудь знакомых, мне будет приятно с ними пообщаться. Если все – незнакомые люди, то мне будет некомфортно.",
          value: "S",
        },
        {
          text: " Я не очень люблю шумные компании и хожу на вечеринки, только чтобы завести или поддержать полезные знакомства. Мне приятнее посидеть и поговорить с одним-двумя людьми в покое и тишине.",
          value: "C",
        },
      ],
    },
    {
      text: "На этой же вечеринке вас попросили произнести тост. Ваша реакция:",
      answer: [
        {
          text: "Я не люблю привлекать к себе внимание, ненавижу произносить тосты. Я не буду ни соглашаться, ни отказываться, а просто как-нибудь увильну.",
          value: "S",
        },
        {
          text: "Я хороший рассказчик и знаю пару прикольных тостов. Все будут в восторге.",
          value: "I",
        },
        {
          text: "Я не боюсь произносить тосты, даже получаю от этого удовольствие, скажу что-нибудь умное и по делу.",
          value: "D",
        },
        {
          text: "Я, скорее всего, откажусь под убедительным предлогом. Но если мне надо произвести хорошее впечатление для пользы дела, то могу и произнести уместный изящный тост.",
          value: "C",
        },
      ],
    },
    {
      text: "Ваш начальник дал вам и вашему сослуживцу задание, но не назначил ответственного за его выполнение. Вы оба понадеялись друг на друга и забыли про задание. Сейчас ваш начальник ругает вас за невыполнение задания. Ваша реакция:",
      answer: [
        {
          text: "Негативные эмоции, которые никак не проявляются внешне. Я в состоянии контролировать себя, но теперь я знаю, чего можно ожидать от этих людей, буду осторожнее в работе с ними в будущем.",
          value: "C",
        },
        {
          text: "Очень эмоциональная реакция, возможны слезы у девушки. Ну да, я – рассеянный и не очень пунктуальный человек, но не я один (одна) виноват(а). Буду жаловаться на них всем друзьям и знакомым.",
          value: "I",
        },
        {
          text: "Обида на сослуживца. Как он мог так подставить меня! Обида на начальника. Я буду долго переживать из-за этого, скорее всего, молча.",
          value: "S",
        },
        {
          text: "Злость на начальника, потому что он безграмотный руководитель, и/или на сослуживца, потому что он меня подставил. Всплеск агрессивных эмоций. Скорее всего, кому-нибудь из них что-нибудь выскажу.",
          value: "D",
        },
      ],
    },
    {
      text: "Вам дали важное задание. Срок выполнения – через месяц, а выполнить его можно за две недели. Ваша реакция:",
      answer: [
        {
          text: "Лучше поскорее выполню задание и сдам его. И в глазах начальства буду хорошо выглядеть, и у меня время для других дел будет.",
          value: "D",
        },
        {
          text: "Сначала мне надо подумать, как лучше всего подойти к выполнению этой работы. Даже если сделаю ее заранее, сразу сдавать не буду. Пусть отлежится, потом еще раз исправлю ошибки. Может, сдам работу за день до срока. ",
          value: "C",
        },
        {
          text: "Сразу возьмусь за дело. Но, скорее всего, мне быстро надоест эта работа, и я увлекусь другим заданием. Заброшу этуработу до тех пор, пока не наступит крайний срок. Потом срочно буду все доделывать, может, даже опоздаю со сдачей.",
          value: "I",
        },
        {
          text: "Хотелось бы начать делать сразу, но знаю, что так не получится. Всегда есть более срочные или важные дела, все время что-то отвлекает. Скорее всего, буду выполнять задание до последней минуты.",
          value: "S",
        },
      ],
    },
    {
      text: "Впереди длинные выходные. Вы решили куда-нибудь выбраться. Что вы выберете:",
      answer: [
        {
          text: "Съездить в гости к родственникам или иначе провести день с семьей, супругой(м).",
          value: "S",
        },
        {
            text: "Сходить в парк или на вечеринку с друзьями.",
            value: "I",
        },
        {
          text: "Съездить на картинг или поиграть в футбол (карты) с друзьями.",
          value: "D",
        },
        {
          text: "Сходить на концерт или выставку, можно в одиночку или с близким человеком.",
          value: "C",
        },
      ],
    },
    {
      text: "Если бы вы решили прыгать с парашютом, то по какой причине:",
      answer: [
        {
          text: "Я познакомился с очень интересными людьми (человеком), которые занимаются парашютным спортом. Они меня уговорили присоединиться.",
          value: "I",
        },
        {
          text: "Мне необходимо через это пройти для достижения важной для меня цели",
          value: "C",
        },
        {
          text: "Я вообще люблю риск, адреналин. Хочу узнать, на что я способен.",
          value: "D",
        },
        {
          text: " Меня считают тихоней. Я всегда в тени, отмалчиваюсь. Хочу доказать себе и другим, что я не трус и не тряпка.",
          value: "S",
        },
      ],
    },
    {
      text: "Какие комментарии вы слышите чаще (как на работе, так и дома) в свой адрес:",
      answer: [
        {
          text: "«А быстрее нельзя?» «Ты опять тянешь время!» «Сколько можно обсуждать одно то же».",
          value: "SC",
        },
        {
          text: "«Пожалуйста, помедленнее» «Ты опять всех торопишь, мы никуда не опаздываем!» «Тебе уже все ясно, а мне – еще нет. Давай не торопясь все обсудим».",
          value: "DI",
        },
      ],
    },
    {
      text: "Вы узнали, что вас решили повысить по службе. Что вы сделаете прежде всего:",
      answer: [
        {
          text: "Сообщите родным и близким о повышении, устроите уютный домашний праздник.",
          value: "S",
        },
        {
          text: "Купите себе какую-нибудь дорогую вещь, чтобы в достойном виде прибыть на место работы в первый день на новой должности (часы, костюм, машину).",
          value: "D",
        },
        {
          text: "Поделитесь радостью с друзьями, закатите большую шумную вечеринку.",
          value: "I",
        },
        {
          text: "Подождете радоваться, сорить деньгами и трезвонить о повышении, пока не начнете работать на новом месте. Ведь еще даже приказ не подписан.",
          value: "C",
        },
      ],
    },
    {
      text: "У вас завтра экзамен. Ваше поведение:",
      answer: [
        {
          text: "Лучше быстро повторить материал, чтобы осталось время на другие важные для вас дела.",
          value: "D",
        },
        {
          text: "Лучше еще раз не спеша все повторить, пускай это даже займет всю ночь.",
          value: "S",
        },
        {
          text: "Лучше хорошенько отоспаться перед экзаменом, чтобы прийти на него со свежей головой. К экзамену вы готовились заранее",
          value: "C",
        },
        {
          text: "Перед смертью не надышишься. Лучше вообще не думать об экзамене, а пойти повеселиться",
          value: "I",
        },
      ],
    },
    {
      text: "Как вы считаете, что является главным для победы, для достижения успеха:",
      answer: [
        {
          text: "Личные усилия каждого. Каждый человек должен отвечать за себя, выкладываться по максимуму, не прятаться за спинами других.",
          value: "DC",
        },
        {
          text: "Главное – командная работа, люди могут добиться чего-либо только вместе, помогая друг другу, поддерживая друг друга.",
          value: "IS",
        },
      ],
    },
    {
      text: "Если бы вы решили открыть свой бизнес (успех и одинаковая прибыльность гарантированы), что бы вы выбрали (абстрагируйтесь от вашей профессии):",
      answer: [
        {
          text: "Фирму финансового консалтинга или фирму по борьбе с вредными насекомыми.",
          value: "C",
        },
        {
          text: "Охранную фирму или оружейный магазин.",
          value: "D",
        },
        {
          text: "Ресторан или ночной клуб.",
          value: "I",
        },
        {
          text: "Медицинский центр или бюро добрых услуг.",
          value: "S",
        },
      ],
    },
    {
      text: "У вас – новый просторный кабинет. Чем вы украсите его стены:",
      answer: [
        {
          text: "Своими фото с известными людьми или яркими современными картинами.",
          value: "I",
        },
        {
          text: "Фото супруга(и) и детей или групповыми фото ваших сослуживцев на корпоративе.",
          value: "S",
        },
        {
          text: "Дипломами или нейтральными картинами.",
          value: "C",
        },
        {
          text: "Портретом президента или старинной саблей.",
          value: "D",
        },
      ],
    },
    {
      text: "Что вы цените более всего в одежде?",
      answer: [
        {
          text: "В одежде обязательно должна быть какая-нибудь изюминка, кураж.",
          value: "I",
        },
        {
          text: "Одежда должна выглядеть дорого и круто.",
          value: "D",
        },
        {
          text: "Одежда должна быть удобной.",
          value: "S",
        },
        {
          text: "Одежда должна быть качественной и уместной, то есть соответствовать возрасту, фигуре и ситуации, в которой ее одеваеш",
          value: "C",
        },
      ],
    },
    {
      text: "Вам надо выбрать соревнование, в котором вам комфортнее всего будет участвовать и где у вас больше шансов на победу. Что вы выберете?",
      answer: [
        {
          text: "Индивидуальные соревнования, где оценивается сообразительность, а не быстрота реакции (шахматы, бильярд, покер).",
          value: "C",
        },
        {
          text: "Индивидуальные соревнования на скорость и смелость (прыжки с парашютом, автогонки, горные лыжи).",
          value: "D",
        },
        {
          text: "Командные соревнования, лучше какие-нибудь необычные (футбол в грязи, всякие корпоративные веселые старты).",
          value: "I",
        },
        {
          text: "Командные соревнования, где требуется взаимная поддержка, взаимодействие всей команды (соревнование университетов по созданию лучшего робота, керлинг).",
          value: "S",
        },
      ],
    },
    {
      text: "Вам надо выбрать гостиницу в Питере. Что вы выберете?",
      answer: [
        {
          text: "Какую-нибудь приличную гостиницу в центре, чтобы не стыдно было.",
          value: "D",
        },
        {
          text: "Какой-нибудь прикольный необычный мини-отель",
          value: "I",
        },
        {
          text: "Гостиницу, где раньше останавливался или которую порекомендуют знакомые.",
          value: "S",
        },
        {
          text: "Гостиницу с идеальным соотношением цена/качество. Может быть, в старинном доме в ретростиле.",
          value: "C",
        },
      ],
    },
  ],
};
