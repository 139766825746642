import { Route, Routes } from "react-router-dom"
import App from "./App"
import Admin from "./Admin"

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/admin-e3mfe3kf" element={<Admin />} />
    </Routes>
  )
}