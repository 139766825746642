import React, { useEffect, useState } from "react";
import { onValue, ref } from "firebase/database";
import { db } from ".";

type TResult =  {
    id: string
    answer: string[]
    result: {
        d: number
        i: number
        s: number
        c: number
    }
    name?: string
    lastname?: string

}
type TRESULT_ENUM = 'd' | 'i' | 's' | 'c' | ''
type TUserResult =  {

        d: number
        i: number
        s: number
        c: number


}

function Admin() {
  const [result, setResult] = useState<TResult[]>([]);
  const [sortTable, setSortTable] = useState<TRESULT_ENUM>()
  const Ref = ref(db, "disc_test/");

  useEffect(() => {
    onValue(Ref, (snapshot) => {
      const data = snapshot.val();
      setResult(Object.keys(data).map((item) => ({id: item, ...data[item]})));
    });
  }, []);
  const colorResult = (userResult:any)=> {
    let colorKey = "d";
    let max = "0"
    Object.keys(userResult).map((item => {
        colorKey = max > userResult[item] ? colorKey : item
        max = max > userResult[item] ? max : userResult[item]        
    }))
    switch (colorKey) {
        case "d":
          return "bg-red-600";          
        case "i":
          return "bg-yellow-500";     
        case "s":
          return "bg-green-500"; 
        case "c":
          return "bg-blue-500"; 
    }     

  }

  useEffect(() => {
    sortTable ? setResult(result.sort(function(a, b) {
        return a.result[sortTable] - b.result[sortTable];
      })) : setResult(result.reverse());
  }, [sortTable]);

  return (
    <div className="container mx-auto px-8">
      <div className="text-3xl font-extrabold py-4 text-slate-900">
        Результаты
      </div>
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer"
                      onClick={()=>sortTable === 'd' ?  setSortTable('') : setSortTable('d')}
                    >
                      D
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer"
                      onClick={()=>sortTable === 'i' ?  setSortTable('') : setSortTable('i')}
                    >
                      I
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer"
                      onClick={()=>sortTable === 's' ?  setSortTable('') : setSortTable('s')}
                    >
                      S
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase cursor-pointer"
                      onClick={()=>sortTable === 'c' ?  setSortTable('') : setSortTable('c')}
                    >
                      C
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {result.map((item, indx) => (
                    <tr key={`item-${indx}`}>
                      <td className="px-6 py-4 whitespace-nowrap flex items-center text-sm font-medium text-gray-800 ">
                        <div className={`w-2 h-2 mr-4 rounded-full  ${colorResult(item?.result)}`}></div> {item?.name} {item?.lastname}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                        {item?.result.d}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                      {item?.result.i}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                      {item?.result.s}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 ">
                      {item?.result.c}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
